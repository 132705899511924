import axios from "axios";
import { useEffect, useState } from "react";

const useFetchAxios = (url) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const reload = () => {
    (async () => {
      setIsLoading(true);
      try {
        const state = JSON.parse(window.localStorage.getItem("STATE"));
        axios.defaults.headers.common["Authorization"] = state?.token || "";
      } catch (err) {
        console.log(err);
      }

      await axios
        .get(url)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          setIsError(true);
          setError(err.response.data.msg);
        });

      setIsLoading(false);
    })();
  };

  useEffect(() => {
    reload();
  }, []);

  return [isLoading, isError, data, error, reload];
};

export { useFetchAxios };
