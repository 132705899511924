import { css } from "@emotion/css";
import axios from "axios";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import AppLoader from "../component/AppLoader";

import { useDispatch} from 'react-redux';
import { login } from "../actions/auth";
// http://localhost:3000/paypal/E8ufQan5/cancle?token=EC-4M23601686043041W

const AppPaypal = withRouter((props) => {
  const [status, setStatus] = useState(null);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      setIsLoading(true);

      const { status, customId, jwt } = props.match.params;

      const { PayerID, paymentId, token } = parse(props.location.search);

      axios
        .post("/api/payment/paypal/verify", {
          paypalInfo: { PayerID, paymentId, token },
          customId,
          jwtToken: jwt,
        })
        .then((res) => {
          console.log(res.data.data);
          setStatus("success");
          setData(res.data.data);
          dispatch(login({
            email: res.data.data.user.email,
            name: res.data.data.user.name,
            token: res.data.data.token,
          }));
        })
        .catch((err) => {
          setData(err.response.data.data);

          const res = err.response;

          dispatch(login({
            email: res.data.data.user.email,
            name: res.data.data.user.name,
            token: res.data.data.token,
          }));
          setStatus("canceled");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [props]);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <div>
      <div class="page-wrapper">
        <section class="login-wrap pt-60 pb-60">
          {/* <img
            src="assets/images/bg-img/shape-1.png"
            alt="Image"
            class="login-shape-1"
          />
          <img
            src="assets/images/bg-img/shape-2.png"
            alt="Image"
            class="login-shape-2"
          /> */}
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-md-12 mb-20 margin-auto">
                <div class="thank-you-box">
                  <div class="check-icon-1 text-center">
                    {status === "success" && (
                      <i class="fa fa-check check-icon"></i>
                    )}
                    {status === "canceled" && (
                      <i
                        class={
                          "fa fa-solid fa-circle-xmark" +
                          " " +
                          css`
                            font-size: 90px;
                            border-radius: 50px;
                            padding: 11px;
                            color: red;
                          `
                        }
                      ></i>
                    )}

                    <h6 class="mt-3">Hey {data?.user?.name}</h6>
                    <h6 class="thank-you mt-2">
                      {status === "success" && "Your Order is Confirmed !"}
                      {status === "canceled" && "Your order is not confirmed"}
                    </h6>
                  </div>
                  <div class="mt-2 order-confirmation">
                    <table class="table border-none">
                      <tbody>
                        <tr>
                          <th>
                            <span>Name</span>
                          </th>
                          <th>
                            <span>{data?.user?.name}</span>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <span>Name Of Food</span>
                          </th>
                          <th>
                            <span>{data?.paymentInfo?.product?.name}</span>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <span>Quantity</span>
                          </th>
                          <th>
                            <span>{data?.paymentInfo?.qty}</span>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <span>Pay Amount</span>
                          </th>
                          <th>
                            <span>
                              $
                              {status === "canceled"
                                ? 0
                                : data?.paymentInfo?.totalPrice}
                            </span>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Link to="/" class="btn style1">
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
});

export default AppPaypal;
