import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import AppSideBar from "../../component/AppSidebar";
import { useSelector } from "react-redux";

const Index = () => {
  const [sideBarWidth, setSideBarWidth] = useState("");

  const { isAdmin } = useSelector(state=> state.auth);

  if (!isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <AppSideBar setSideBarWidth={setSideBarWidth} />
    </>
  );
};

export default Index;
