import parse from "html-react-parser";
import AppLoader from "../component/AppLoader";
import { useFetchAxios } from "../hooks/useFetchAxios";
import FooterLink from "./FooterLink/FooterLink";

const CancellationRefundsPolicy = () => {


    return (
        <section className="py-4">

            <div className="container">

                <div className="row">

                    <div className="col-md-12">

                        <div className="description-policy">

                            <h3 className="text-center">Cancellation & Refunds Policy</h3>

                            <h5>Cancellations, Refund and Return policy</h5>

                            <p>
                                Sorry, we cannot cancel, refund or exchange  on any of the items once the order is placed.
                            </p>

                        </div>

                    </div>

                </div>
                <FooterLink></FooterLink>
            </div>

        </section>
    );
};

export default CancellationRefundsPolicy;
