import { css } from "@emotion/css";
import axios from "axios";
import cogoToast from "cogo-toast";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Img from "../assets/image.png";
import AppLoader from "../component/AppLoader";
import { useFetchAxios } from "../hooks/useFetchAxios";
import { useSelector } from "react-redux";

const AppHome = (props) => {
  const { push } = useHistory();
  const [isLocationLoad, setIsLocationLoad] = useState(true);
  const [userLocation, setUserLocation] = useState("");

  const { isAuth } = useSelector((state) => state.auth);

  const [defaultCity, setDefaultCity] = useState("");

  const [isLoading, isError, data] = useFetchAxios("/api/recipe");

  const [isLocationLoading, isLocationError, locationData] = useFetchAxios(
    "/api/public/location"
  );

  useEffect(() => {
    if (Array.isArray(locationData) && Array.isArray(data?.deliveryLocation)) {
      const response = locationData.filter((x) =>
        data.deliveryLocation.includes(x._id)
      );

      if (Array.isArray(response) && response.length === 1) {
        setDefaultCity(response[0]._id);
      }
    }
  }, [locationData, data]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setIsLocationLoad(true);

      try {
        const state = JSON.parse(window.localStorage.getItem("STATE"));
        axios.defaults.headers.common["Authorization"] = state?.token || "";
      } catch (err) {
        console.log(err);
      }
      try {
        await axios.get("/api/user/info").then((res) => {
          const isInclides = data.deliveryLocation.includes(
            res.data.data.location
          );

          console.log("RESULT", isInclides ? res.data.data.location : "");

          setUserLocation(isInclides ? res.data.data.location : "");
        });
      } catch (err) {
        console.log("ERROR", err);
      }

      setIsLocationLoad(false);
    })();
  }, [isLoading]);

  useEffect(() => {
    try {
      const parsed = queryString.parse(props.location.search);

      if (parsed?.register === "true") {
        cogoToast.success("Your account is registerd successfully").then(() => {
          push("/");
        });
      }

      if (parsed?.login === "true") {
        cogoToast.success("Your account is login successfully").then(() => {
          push("/");
        });
      }

      if (parsed?.update === "true") {
        cogoToast.success("Your account information is updated successfully");
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  if (isLoading || isLocationLoad || isLocationLoading) {
    return <AppLoader />;
  }

  const handleLocationChange = (e) => {
    setUserLocation(e.target.value);
  };

  const handleSubmit = () => {
    if (!userLocation) {
      cogoToast.error("Please select your location");
      return;
    }

    if (!isAuth) {
      cogoToast.error("Please login to place an order");

      push(`/auth?redirect=/place-an-order?location=${userLocation}`);

      return;
    }

    push(`/place-an-order?location=${userLocation}`);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title
            className={css`
              font-weight: normal !important;
            `}
          >
            Why you should order from us ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Cost</h3>
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Other APPS</th>
                <th>OneFoodPot</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Item cost :- 11
                  <small style={{ fontSize: "0.6rem" }}>
                    (More then menu price)
                  </small>
                </td>
                <td>Item cost :- 10</td>
              </tr>
              <tr>
                <td>Service Fee :- 2.5</td>
                <td>Service Fee :- 0</td>
              </tr>
              <tr>
                <td>Delivery Fee :- 0.50</td>
                <td>Delivery Fee :- 0</td>
              </tr>
              <tr>
                <td>Small order fees :- 2.00</td>
                <td>Small order fee :- 0</td>
              </tr>
              <tr>
                <td>Total cost :- 16.00</td>
                <td>Total Cost :- 9.5</td>
              </tr>
            </tbody>
          </table>
          <h3>Hot food served.</h3>
          <div class="row">
            <div class="d-flex align-items-center col-md-6">
              <h1 class="lead">
                We serve via Food Insulated Container, So it feels like straight
                out of Oven !!!
              </h1>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src={Img} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <section className="menu-section hero-img-area">
          <img
            src="assets/images/bg-img/shape-3.png"
            alt="Image"
            className="login-shape-2"
          />
          <img
            src="assets/images/bg-img/shape-2.png"
            alt="Image"
            className="login-shape-1"
          />

          <div className="container">
            <div className="text-center my-2">
              <p className="fs-4" style={{ color: "#e88f3b" }}>
                {data.remainingQuantity
                  ? `Hurry, only ${
                      data?.remainingQuantity || 0
                    } orders left! Grab yours now before they're gone!`
                  : `Maximum number of orders reached !!! `}
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-15">
                <div className="dishes-img">
                  <img
                    src={`/api/file/${data?.img}`}
                    alt=""
                    className="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 mt-20 scroll-content"
                style={{ overflow: "auto" }}
              >
                <h1 className="heading-4">{data?.name}</h1>
                <p
                  className={
                    "description" +
                    " " +
                    css`
                      font-weight: bold;
                      margin-top: 0.7rem;
                    `
                  }
                >
                  Offered by:{" "}
                  <span
                    className={css`
                      font-weight: normal;
                      margin-top: 0.7rem;
                    `}
                  >
                    {data?.offerdBy}
                  </span>
                </p>
                <p className="description">
                  <span>
                    <a
                      className={
                        "review-link btn style1 text-white" +
                        " " +
                        css`
                          display: block !important;
                          float: none !important;
                          width: 25% !important;
                          margin-top: -0.5rem;
                          margin-bottom: 0.5rem;

                          @media (max-width: 768px) {
                            width: 50% !important;
                          }
                        `
                      }
                      href={data?.yelpLink}
                      target="_blank"
                    >
                      Yelp Reviews
                      <br />
                    </a>
                  </span>
                  {data?.description}
                </p>
                <div className="row d-flex align-items-center">
                  {/* col-lg-6 col-6 price-col mt-3 */}
                  <div className="col-5 col-md-4">
                    <h3
                      className={
                        "price" +
                        " " +
                        css`
                          @media only screen and (max-width: 425px) {
                            font-size: 19px;
                          }
                        `
                      }
                    >
                      <span className="line-through">${data.regularPrice}</span>
                      &nbsp;&nbsp;&nbsp;${data.discountPrice}
                    </h3>
                  </div>
                  {/* col-lg-12 mt-3 */}
                  <div className="col-7 col-md-8">
                    <select
                      className="select-area"
                      style={{ color: "#000000 !important" }}
                      value={userLocation}
                      // value="6207af9ac483a46fac7b692e"
                      onChange={handleLocationChange}
                    >
                      <option value={""}>--- Select Area ---</option>
                      {locationData
                        // .filter((x) => data.deliveryLocation.includes(x._id))
                        .map((x) => {
                          return (
                            <option value={x._id}>
                              {x.name} - {x.time}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* col-lg-6 col-6 */}
                  <div className="my-3 d-flex w-100">
                    <button
                      className={`btn w-100 text-white ${
                        data?.remainingQuantity ? "style1" : "bg-secondary"
                      }`}
                      onClick={handleSubmit}
                      disabled={!data?.remainingQuantity}
                    >
                      Order Now
                    </button>
                  </div>
                  <h5 className="my-4"> Why should you order from us?</h5>
                  <div
                    className={
                      "container shadow border border-2 rounded-3 text-center " +
                      css`
                        .bg-orange {
                          background-color: #e88f3b;
                          height: 3.5rem;
                          font-weight: 400;
                          width: 25%;
                        }
                        .heading {
                          font-weight: 600;
                          height: 5rem;
                        }
                        .col-4 {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                        .col-3 {
                          width: 35%;
                        }

                        @media (max-width: 574px) {
                          .bg-orange {
                            height: 4.2rem;
                            width: 30%;
                          }
                          .heading {
                            height: 5rem;
                          }
                        }
                      `
                    }
                    style={{
                      backgroundColor: "#faf3eb",
                      width: "96%",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <div className="row justify-content-start align-items-center ">
                      <div className="col-3"></div>
                      <div className="col-4 fs-6  heading">
                        {" "}
                        Other Food <br /> Delivery App
                      </div>
                      <div className="col-4 fs-6  bg-orange heading">
                        One Food <br /> Pot
                      </div>
                    </div>
                    <div className="row justify-content-start align-items-center">
                      <div className="col-3 sub-heading text-start ps-4 fs-6">
                        {" "}
                        Average Cost
                      </div>
                      <div className="col-4">---</div>
                      <div className="col-4 bg-orange"> 30-40% less</div>
                    </div>
                    <div className="row justify-content-start align-items-center">
                      <div className="col-3 sub-heading text-start ps-4 fs-6">
                        Service Fee
                      </div>
                      <div className="col-4">
                        <i className="fa fa-check fa-lg"></i>
                      </div>
                      <div className="col-4 bg-orange">
                        <i class="fa-sharp fa-solid fa-xmark fa-lg"></i>
                      </div>
                    </div>
                    <div className="row justify-content-start align-items-center">
                      <div className="col-3 sub-heading text-start ps-4 fs-6">
                        Delivery Fee
                      </div>
                      <div className="col-4">
                        <i className="fa fa-check fa-lg"></i>
                      </div>
                      <div className="col-4 bg-orange">
                        <i class="fa-sharp fa-solid fa-xmark fa-lg"></i>
                      </div>
                    </div>
                    <div className="row justify-content-start align-items-center">
                      <div className="col-3 sub-heading text-start ps-4 fs-6">
                        Small Order Fee
                      </div>
                      <div className="col-4">
                        <i className="fa fa-check fa-lg"></i>
                      </div>
                      <div className="col-4 bg-orange">
                        <i class="fa-sharp fa-solid fa-xmark fa-lg"></i>
                      </div>
                    </div>
                    <div className="row justify-content-start align-items-center">
                      <div className="col-3 sub-heading text-start ps-4 fs-6">
                        Hot Food Guarantee!!!
                      </div>
                      <div className="col-4">
                        <i class="fa-sharp fa-solid fa-xmark fa-lg"></i>
                      </div>
                      <div className="col-4 bg-orange">
                        <i className="fa fa-check fa-lg"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AppHome;
