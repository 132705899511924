import { reduxConstants } from "../utils/constant"

export const login = (payload) => {
    return {
        type: reduxConstants.LOGIN,
        payload
    }
}
export const logout = () => {
    return {
        type: reduxConstants.LOGOUT
    }
}
export const updateUser = (payload) => {
    return {
        type: reduxConstants.UPDATE_USER,
        payload
    }
}
