import React from "react";
import { css } from "@emotion/css";
import { Row, Col, Card } from "react-bootstrap";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import AppLoader from "../../component/AppLoader";
import { Link, useHistory } from "react-router-dom";

const CardStatus = ({ name, count, fontName }) => {
  return (
    <div class="widget-stat card border-0">
      <div class="card-body p-4">
        <div class="media ai-icon" style={{ width: "120% !important" }}>
          <span
            class="mr-3 text-primary"
            style={{ backgroundColor: "#ffe9cf", color: "black" }}
          >
            <i
              class={fontName}
              id="icon-customers"
              style={{ color: "black" }}
            ></i>
            {/* <svg
              id="icon-customers"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-user"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg> */}
          </span>
          <div class="media-body pe-4">
            <h4 class="mb-0">{count}</h4>
            <p class="mb-1" style={{ width: "130%" }}>
              {name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SimpleCard = ({ name, faName, link }) => {
  return (
    <Card className="card border-0 text-center">
      <Card.Body as={Link} to={link}>
        <Card.Title>
          <i class={faName}></i>
          {"   "} {name}
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

const hoverStyle = css`
  :hover {
    cursor: pointer;
  }
`;

const AppDashboard = () => {
  const [isLoading, isError, data, error, reload] = useFetchAxios(
    "/api/user/admin/counts"
  );

  if (isLoading) {
    return <AppLoader />;
  }

  const { push } = useHistory();

  const handlePush = (itx) => () => push(itx);

  return (
    <div className={cssStyles}>
      <Row>
        <Col
          xs="12"
          md="12"
          lg="4"
          onClick={handlePush("/admin/orders")}
          className={hoverStyle}
        >
          <CardStatus
            name="Total sales"
            count={`$${data?.salesNo}`}
            fontName="fa fa-solid fa-dollar-sign"
          />
        </Col>
        <Col
          xs="12"
          md="12"
          lg="4"
          onClick={handlePush("/admin/orders")}
          className={hoverStyle}
        >
          <CardStatus
            name="Total orders"
            count={data?.orderNo}
            fontName="fa fa-solid fa-map"
          />
        </Col>
        <Col
          xs="12"
          md="12"
          lg="4"
          onClick={handlePush("/admin/customers")}
          className={hoverStyle}
        >
          <CardStatus
            name="Customers"
            count={data?.userNo}
            fontName="fa fa-solid fa-user"
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="4">
          <SimpleCard
            name={"Recipe of the day"}
            faName="fa fa-solid fa-burger"
            link="/admin/recipe"
          />
        </Col>

        <Col xs="12" md="12" lg="4">
          <SimpleCard
            name={"Order of the Day"}
            faName="fa fa-solid fa-tag"
            link="/admin/orders"
          />
        </Col>
        <Col xs="12" md="12" lg="4">
          <SimpleCard
            name={"Locations"}
            faName="fa fa-solid fa-crosshairs"
            link="/admin/location"
          />
        </Col>
      </Row>
    </div>
  );
};

export default AppDashboard;

const cssStyles = css`
  .media {
    display: flex;
  }
  .card {
    text-align: center;
    margin-bottom: 1.875rem;
    background-color: #fff;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0 solid transparent;
    border-radius: 2px;
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%);
    height: -webkit-calc(100% - 30px);
    height: -moz-calc(100% - 30px);
    height: calc(100% - 30px);
  }
  .card {
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
  .card-body {
    -moz-box-flex: 1;
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  .widget-stat .media {
    padding: 0;
    -moz-box-align: center;
    align-items: center;
  }
  .media {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: flex-start;
  }
  .widget-stat .media > span {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    padding: 10px 12px;
    font-size: 32px;
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    color: #464a53;
    min-width: 85px;
  }
  .bgl-primary {
    background: #ffe9d9;
    border-color: #ffe9d9;
  }
  .media-body {
    -moz-box-flex: 1;
    flex: 1 1;
  }
  .widget-stat .media .media-body p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }
  .widget-stat .media .media-body h4 {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
  }
  .widget-stat .media .media-body span {
    margin-left: 5px;
  }
  .badge-primary {
    background-color: #ff720d;
  }
  .badge {
    line-height: 1.5;
    border-radius: 2px;
    padding: 4px 10px;
    border: 1px solid transparent;
  }
  .badge-primary {
    color: #fff;
  }
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.75rem;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;
