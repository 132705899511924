import React, { useState } from "react";
import axios from "axios";

const usePostAxios = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const postData = async (packageData) => {
    setIsLoading(true);

    let error = null;
    let data = null;

    try {
      const state = JSON.parse(window.localStorage.getItem("STATE"));
      axios.defaults.headers.common["Authorization"] = state?.token || "";
    } catch (err) {
      console.log(err);
    }

    await axios
      .post(url, packageData)
      .then((res) => {
        data = res.data.data;
      })
      .catch((err) => {
        error = err.response.data.msg;
      });

    setIsLoading(false);

    return [data, error];
  };

  return [isLoading, postData];
};

export default usePostAxios;
