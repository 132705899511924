import parse from "html-react-parser";
import AppLoader from "../../component/AppLoader";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import './FooterLink.css';
const FooterLink = () => {
    return (
        <div className="footer_link text-center">
            <a href="./account-deletion-policy">Account Deletion Policy</a>
            &nbsp;|&nbsp;
            <a href="./cancel-refund-policy">Cancel Refund Policy</a>
            &nbsp;|&nbsp;
            <a href="./disclaimer">Disclaimer</a>
            &nbsp;|&nbsp;
            <a href="./privacy-policy">Privacy Policy</a>
            &nbsp;|&nbsp;
            <a href="./shipping">Shipping</a>
            &nbsp;|&nbsp;
            <a href="./termscondition">Terms and Condition</a>
        </div>
    );
};

export default FooterLink;
