import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { useEffect, useRef, useState } from "react";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import AppCms from "../screens/Admin/AppCms";
import AppCustomer from "../screens/Admin/AppCustomer";
import AppDashboard from "../screens/Admin/AppDashboard";
import AppLocation from "../screens/Admin/AppLocation";
import AppPaymentList from "../screens/Admin/AppPaymentList";
import AppPrivacyPolicy from "../screens/Admin/AppPrivacyPolicy";
import AppRecipe from "../screens/Admin/AppRecipe";

import { css } from "@emotion/css";
import AppCashOnDelivery from "../screens/Admin/AppCashOnDelivery";

import { useDispatch} from 'react-redux';
import { logout } from "../actions/auth";

const Sidebar = withRouter((props) => {
  const { setSideBarWidth } = props;

  const dispatch = useDispatch();

  const divRef = useRef(null);

  const [bodyHeight, setBodyHeight] = useState(
    Math.round(window.innerHeight / (100 / 100)) + "px"
  );

  useEffect(() => {
    if (props.location.pathname === "/admin") {
      props.history.push("/admin/dashboard");
    }
  }, []);

  useEffect(() => {
    var body = document.body,
      html = document.documentElement;

    const fullW = document.getElementById("root");

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    setBodyHeight(`${height}px`);
  }, [document.body, document.documentElement, props.location.pathname]);

  const handleLogout = () => dispatch(logout());

  useEffect(() => {
    try {
      setSideBarWidth(divRef.current.offsetWidth);
    } catch (err) {
      console.log(err);
    }
    if (divRef.current) {
    }
  }, [divRef, props.location.pathname]);

  return (
    <>
      <div
        style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
      >
        <CDBSidebar
          textColor="#fff"
          backgroundColor="#333"
          ref={divRef}
          style={{ height: "100vh" }}
        >
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <a
              href="/"
              className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              ONE FOOD
            </a>
          </CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink
                exact
                to="/admin/dashboard"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="columns">
                  Dashboard
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/admin/customers"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="table">Customers</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/admin/recipe" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user">
                  Recipe Of The Day
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/admin/orders" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">
                  Orders
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/admin/location"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="map-marker-alt">
                  Location
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink exact to="/admin/cms" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="align-justify">
                  Cms
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/admin/privacy_policy"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fa fa-light fa-shield">
                  Privacy Policy
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/admin/logout"
                onClick={handleLogout}
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="sign-out-alt">
                  Logout
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>

        <AdminRoutes />
      </div>
    </>
  );
});

export default Sidebar;

const AdminRoutes = () => {
  return (
    <div id="meon-d" style={adminRouteStyle} className={adminRouteCss}>
      <Switch>
        <Route path={"/admin/cms"} component={AppCms} />
        <Route path={"/admin/recipe"} component={AppRecipe} />
        <Route path={"/admin/customers"} component={AppCustomer} />
        <Route path={"/admin/location"} component={AppLocation} />
        <Route path={"/admin/orders"} component={AppPaymentList} />
        <Route path={"/admin/dashboard"} component={AppDashboard} />
        <Route path={"/admin/privacy_policy"} component={AppPrivacyPolicy} />
        <Route path={"/admin/cash-on-delivery"} component={AppCashOnDelivery} />
      </Switch>
    </div>
  );
};

const adminRouteStyle = {
  padding: "3rem",
  paddingBottom: "3rem",
  height: "100vh",
  overflowY: "auto",
  width: "100%",
};

const adminRouteCss = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 425px) {
    padding: 1rem !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 1.5rem !important;
  }
`;
