import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { FormControl } from "react-bootstrap";
import * as Yup from "yup";
import cogoToast from "cogo-toast";

const initSchema = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const AppForgotPassword = () => {
  const handleSubmit = (val, { resetForm }) => {
    axios
      .post("/api/user/forgot-password", { email: val.email })
      .then((res) => {
        cogoToast.success(
          "Please check your email for generating new password"
        );
        resetForm();
        return;
      })
      .catch((err) => {
        cogoToast.error("Account using this email address does not exist");
        return;
      });
  };

  return (
    <div>
      <section className="login-wrap pt-100 pb-70" id="Forgot_form">
        <img
          src="assets/images/bg-img/shape-1.png"
          alt="Image"
          className="login-shape-1"
        />
        <img
          src="assets/images/bg-img/shape-2.png"
          alt="Image"
          className="login-shape-2"
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2">
              <div className="login-form">
                <div className="login-header bg-green">
                  <h2 className="text-center mb-0">Forget Password</h2>
                </div>
                <div className="login-body">
                  <Formik
                    initialValues={initSchema}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                    }) => {
                      return (
                        <form
                          onSubmit={handleSubmit}
                          onChange={handleChange}
                          className="form-wrap"
                          action="#"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label for="email">Email</label>
                                <FormControl
                                  id="email"
                                  name="email"
                                  type="email"
                                  placeholder="Email Address*"
                                  required=""
                                  isInvalid={!!touched.email && !!errors.email}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn style1 w-40 d-block w-100 text-white"
                                >
                                  Forgot Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppForgotPassword;
