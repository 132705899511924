import { Formik } from "formik";
import React from "react";
import { FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
// import "yup-phone";
import "yup-phone-lite";
import usePostAxios from "../../hooks/usePostAxios";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { validationErrorMessage } from "../../utils/constant";
import FieldError from "../../component/FieldError";
import {useDispatch} from "react-redux";
import { login } from "../../actions/auth";
import { formatPhoneNumber } from "../../utils/utils";

const initSchema = {
  name: "",
  email: "",
  mobile: "",
  password: "",
  delivery: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string(validationErrorMessage.type('string'))
    .required(validationErrorMessage.required())
    .min(4, validationErrorMessage.minValue(4))
    .max(24, validationErrorMessage.maxValue(24)),
  email: Yup.string(validationErrorMessage.type('string'))
    .required(validationErrorMessage.required())
    .email(validationErrorMessage.email()),
  mobile: Yup.string(validationErrorMessage.type('string'))
    .phone("CA", validationErrorMessage.phone())
    .required(validationErrorMessage.required()),
  password: Yup.string(validationErrorMessage.type('string'))
    .required(validationErrorMessage.required())
    .min(6, validationErrorMessage.minValue(6))
    .max(18, validationErrorMessage.maxValue(18)),
  // delivery: Yup.string(validationErrorMessage.type('string'))
  //   .required(validationErrorMessage.required()),
});

const AppRegistration = ({ locationData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSendingData, postData] = usePostAxios(
    "/api/user/auth/user?reqister=true"
  );

  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (val) => {
    try {
      val.delivery = locationData[0]._id;
      const [data, err] = await postData(val);
      if (data) {
        await dispatch(login({
          email: data.user.email,
          name: data.user.name,
          token: data.token,
        }));

        return push("/?register=true");
      }
      if (err) {
        throw new Error(err)
      }

    } catch (err) {

      return cogoToast.error(err.message);
    }
  };

  return (
    <div className="login-body">
      <div className="login-header bg-green ">
        <h2 className="text-center mb-20">Registration</h2>
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initSchema}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              onChange={handleChange}
              className="form-wrap"
            >
              <div className="row">
                <div className="col-lg-6 my-2">
                  <label for="name" className="form-label font-weight-bold">Name * </label>
                  <div className=" input-group">
                    <FormControl
                      className="form-control"
                      id="name"
                      type="text"
                      name="name"
                      isInvalid={!!touched.name && !!errors.name}
                      placeholder="John"
                    />
                  </div>
                  {!!touched.name && <FieldError errorMessage={errors.name} />}
                </div>
                <div className="col-lg-6 my-2">
                  <label for="mobile" className="form-label font-weight-bold">Phone * </label>
                  <div className="input-group">
                    <FormControl
                      className="form-control"
                      id="mobile"
                      type="text"
                      name="mobile"
                      value={formatPhoneNumber(values.mobile)}
                      isInvalid={!!touched.mobile && !!errors.mobile}
                      placeholder="292-32X-XXXX"
                      onChange={e=> setFieldValue('mobile', e.target.value)}
                    />
                  </div>
                  {!!touched.mobile && <FieldError errorMessage={errors.mobile} />}
                </div>
                <div className="col-lg-6 my-2">
                  <label for="email" className="form-label font-weight-bold">Email * </label>
                  <div className="input-group">
                    <FormControl
                      className="form-control"
                      id="email"
                      type="text"
                      name="email"
                      isInvalid={!!touched.email && !!errors.email}
                      placeholder="name@email.com"
                    />
                  </div>
                  {!!touched.email && <FieldError errorMessage={errors.email} />}
                </div>
                <div className="col-lg-6 mt-2">
                  <label for="pwd" className="form-label font-weight-bold">Password * </label>
                  <div className="input-group">
                    <FormControl
                      className="form-control"
                      id="pwd"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      isInvalid={!!touched.password && !!errors.password}
                      placeholder="Password"
                    />
                    <span class="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (<i class="fas fa-eye" id="show_eye"></i>) : (<i class="fas fa-eye-slash" id="hide_eye"></i>)}
                    </span>
                  </div>
                  {!!touched.password && <FieldError errorMessage={errors.password} />}
                </div>

                <div className="col-lg-12 mt-5">
                  <div className="form-group">
                    <button
                      disabled={isSendingData}
                      type="submit"
                      className="btn style1 w-50 d-block"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  )
};

export default AppRegistration;
