import React from "react";
import { css } from "@emotion/css";

const AppWropForm = ({ title, children, exportToExcel, isExportToExcel }) => {
  return (
    <div>
      <div class="card border-0" style={{ width: "100%" }}>
        <div
          class={
            "card-header" + " " + isExportToExcel
              ? css`
                  display: flex;
                  justify-content: space-between;
                  padding-left: 1.5rem;
                  padding-right: 1.5rem;
                  padding-top: 1rem;
                `
              : ""
          }
          style={{ backgroundColor: "#fff" }}
        >
          <h4
            class={
              "card-title" +
              " " +
              css`
                @media only screen and (max-width: 425px) {
                  font-size: 18px;
                }
              `
            }
          >
            {title}
          </h4>
          {isExportToExcel && (
            <button
              onClick={exportToExcel}
              className={
                "btn style1" +
                " " +
                css`
                  @media only screen and (max-width: 425px) {
                    font-size: 13px;
                  }
                `
              }
            >
              Export To Excel
            </button>
          )}
        </div>
        <div class={"card-body" + " "}>{children}</div>
      </div>
    </div>
  );
};

export default AppWropForm;
