import { Route, Switch, withRouter } from "react-router-dom";

import AppHeader from "./component/AppHeader";
import AppAdmin from "./screens/Admin/Index";
import AppFrontCms from "./screens/AppFrontCms";
// import AppGPay from "./screens/AppGPay";
import AppHome from "./screens/AppHome";
import AppHowDoesItWork from "./screens/AppHowDoesItWork";
import AppOrder from "./screens/AppOrder";
import AppPaypal from "./screens/AppPaypal";
import AppPlaceHolder from "./screens/AppPlaceHolder";
import AppProfile from "./screens/AppProfile";
import AppStripe from "./screens/AppStripe";
import AppForgotPassword from "./screens/Auth/AppForgotPassword";
import AppNewPassword from "./screens/Auth/AppNewPassword";
import FreshChat from 'react-freshchat';
import AccountDetailsPolicy from "./screens/AccountDeletionPolicy";
import CancellationRefundsPolicy from "./screens/CancellationRefundsPolicy";
import Disclaimer from "./screens/Disclaimer";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import Shipping from "./screens/Shipping/Shipping";
import TermsConditions from "./screens/TermsConditions/TermsConditions";
import AppAuth from "./screens/Auth/AppAuth";

const App = withRouter((props) => {
  // useEffect(() => {
  //   const tawk = new TawkTo(
  //     process.env.REACT_APP_PROPERTYID,
  //     process.env.REACT_APP_TAWKID
  //   );

  //   delay(
  //     () => {
  //       tawk.onStatusChange((status) => console.log(status));
  //     },
  //     3000,
  //     "later"
  //   );
  // }, []);

  return (
    <>
      {!props.location.pathname.startsWith("/admin") && <AppHeader />}
      <Switch>
        <Route path="/" exact component={AppHome} />

        <Route path="/order" exact component={AppOrder} />
        <Route path="/profile" exact component={AppProfile} />
        <Route path="/place-an-order" exact component={AppPlaceHolder} />

        <Route path="/auth" exact component={AppAuth} />
        <Route path="/forgot-password" exact component={AppForgotPassword} />

        <Route path="/rw-pass/:jwt" exact component={AppNewPassword} />

        <Route path="/stripe" exact component={AppStripe} />
        <Route
          path="/paypal/:customId/:jwt/:status"
          exact
          component={AppPaypal}
        />
        <Route path="/how-does-it-work" exact component={AppHowDoesItWork} />
        <Route path="/c/privacy_policy" exact component={AppFrontCms} />
        <Route path="/account-deletion-policy" exact component={AccountDetailsPolicy} />
        <Route path="/cancel-refund-policy" exact component={CancellationRefundsPolicy} />
        <Route path="/disclaimer" exact component={Disclaimer} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/shipping" exact component={Shipping} />
        <Route path="/termscondition" exact component={TermsConditions} />
        <Route path="/admin" exact component={AppAdmin} />
        <Route path="/admin/*" exact component={AppAdmin} />
        {/* <Route path="/gpay" exact component={AppGPay} /> */}
      </Switch>
      
      
      <FreshChat
        token="c553d6a2-6d90-43a0-828d-2fb6751965ba"
        email="harsh.empbweb@gmail.com"
        first_name="Harsh"
        onInit={widget => { 
          widget.user.setProperties({
            email: "harsh.empbweb@gmail.com",
            first_name: "Harsh",
            last_name: "Chauhan",
            phone: "9824281021",
          })
        }}
      /> 
     


    </>
  );
});

export default App;
