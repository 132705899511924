import parse from "html-react-parser";
import AppLoader from "../../component/AppLoader";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import FooterLink from "../FooterLink/FooterLink";
import './Shipping.css';

const Shipping = () => {
    return (
        <section className="py-4">

            <div className="container">

                <div className="row">

                    <div className="col-md-12">

                        <div className="description-policy">

                            <h3 className="text-center">Shipping Policy</h3>

                            <p>
                                1.	Delivery periods quoted at the time of ordering are approximate only and may vary. Goods will be delivered to the address designated by customers at the time of ordering.
                            </p>

                            <p>
                                2.	In the case delivery is done by <a href="http://1foodpot.com/">www.1foodpot.com</a> or a third party delivery partner assigned by <a href="http://1foodpot.com/">www.1foodpot.com</a>, we will give great care to deliver in a timely manner. No responsibility is taken for late delivery by <a href="http://1foodpot.com/">www.1foodpot.com</a> in either case.
                            </p>

                            <p>
                                3.	All orders are delivered by a reputable delivery driver. We and the Participating vendor will make every effort to deliver within the time stated; however, we will not be liable for any loss caused to you by ordering late. If the Goods are not delivered within the estimated delivery time quoted by us, please contact the participating vendor first. You may also contact us by telephone or email and we will try to ensure that you receive your order as quickly as possible.
                            </p>

                            <p>
                                4.	In case of a late delivery, the delivery charge will neither be voided nor refunded by <a href="http://1foodpot.com/">www.1foodpot.com</a>
                            </p>
                            <p>
                                5.	All risk in the Goods and the Food Delivery shall pass to customers upon
                            </p>

                            <p>
                                6.	If you fail to accept delivery of Food Delivery and/or Goods at the time they are ready for delivery, or we are unable to deliver at the nominated time due to your failure to provide appropriate instructions, or authorizations, then such goods shall be deemed to have been delivered to you and all risk and responsibility in relation to such goods shall pass to you. Any storage, insurance and other costs which we incur as a result of the inability to deliver shall be your responsibility and you shall indemnify us in full for such cost.
                            </p>

                            <p>
                                7.	You must ensure that at the time of delivery of Food Delivery and/or Goods adequate arrangements, including access where necessary, are in place for the safe delivery of such goods. We cannot be held liable for any damage, cost or expense incurred to such goods or premises where this arises as a result of a failure to provide adequate access or arrangements for delivery.
                            </p>

                            <p>
                                8.	Participating restaurants, who will prepare your order, aim.
                            </p>

                            <ul className="ul">
                                <li>
                                    •	To deliver the product to you at the place of delivery requested by you in your order
                                </li>

                                <li>
                                    •	To deliver within the time confirmed by the vendor;
                                </li>

                                <li>
                                    •	To inform you if they expect that they are unable to meet the estimated delivery time.
                                </li>

                            </ul>

                        </div>

                    </div>
                    <FooterLink></FooterLink>
                </div>

            </div>

        </section>

    );
};

export default Shipping;
