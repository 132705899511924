import { Global } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import axios from "axios";

import App from "./App";
import { appCss } from "./utils/constant";

import "@fortawesome/fontawesome-free/css/all.css";
import AppLoader from "./component/AppLoader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import {Provider} from 'react-redux';
import store from "./store";

dayjs.extend(utc);
dayjs.extend(timezone);

axios.defaults.baseURL =
  process.env.REACT_APP_BASE_URL || "http://localhost:5000";

ReactDOM.render(
  <React.StrictMode>
    <Global styles={appCss} />
    <Suspense fallback={<AppLoader />}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// https://medium.com/geekculture/tips-on-google-pay-and-apple-pay-with-stripe-3eaaabd6ccab
