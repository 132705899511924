import parse from "html-react-parser";
import AppLoader from "../component/AppLoader";
import { useFetchAxios } from "../hooks/useFetchAxios";

const AppHowDoesItWork = () => {
  const [isLoading, isError, data, error, reload] = useFetchAxios(
    "/api/privacy_policy"
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <section className="menu-section hero-img-area how-it-work-section">
      <div className="container">{parse(data?.cms || "")}</div>
    </section>
  );
};

export default AppHowDoesItWork;
