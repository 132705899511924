import jwt_decode from "jwt-decode";
import { reduxConstants } from "../utils/constant";

const initialState = JSON.parse(window.localStorage.getItem("STATE")) || {
    isAuth: false,
    isAdmin: false,
    user: {
        email: "",
        name: "",
    },
    token: "",
};

export default function auth(state = initialState, action) {
    let newState = {}
    switch (action.type) {
        case reduxConstants.LOGIN:
            const { isAdmin } = jwt_decode(action.payload.token);
            newState = {
                ...state,
                isAuth: true,
                isAdmin: isAdmin,
                user: {
                    email: action.payload.email,
                    name: action.payload.name,
                },
                token: action.payload.token,
            };
            localStorage.setItem('STATE', JSON.stringify(newState));
            return newState;
        case reduxConstants.LOGOUT:
            localStorage.clear();
            return {
                ...state,
                isAuth: false,
                isAdmin: false,
                user: {
                    email: "",
                    name: "",
                },
                token: "",
            };
        case reduxConstants.UPDATE_USER:
            newState = {
                ...state,
                user: {
                    email: state.user.email,
                    name: action.payload.name,
                },
            };
            localStorage.setItem('STATE', JSON.stringify(newState));
            return newState;
        default:
            return state;
    }
} 