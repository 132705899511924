import parse from "html-react-parser";
import AppLoader from "../component/AppLoader";
import { useFetchAxios } from "../hooks/useFetchAxios";
import FooterLink from "./FooterLink/FooterLink";

const AccountDetailsPolicy = () => {
 return (
        <section className="py-4">

            <div className="container">
                <div className="row">

                    <div className="col-md-12">

                        <div className="description-policy">

                            <h3 className="text-center">Account Deletion Policy</h3>

                            <h5>Background</h5>

                            <p>
                                As part of its everyday business operations, One Food Pot collects and stores records of many types in a variety of different formats. The relative importance and sensitivity of these records also varies and is subject to One Food Pot security classification scheme.
                            </p>

                            <p>
                                One Food Pot has implemented systems, applications and procedures to minimize the risks of loss, destruction, falsification, unauthorized access and unauthorized release, and a range of controls are used to ensure this, including backups, access control and encryption.
                            </p>

                            <p>
                                One Food Pot must comply with all the legal, regulatory, and contractual requirements which apply to its collection, storage, retrieval and destruction of records, in particular such records which contain personal data.
                            </p>

                            <h5>Scope</h5>

                            <p>
                                This policy applies to cases where a customer has decided to stop using the One Food Pot application, and the various types of data that have been accumulated in the One Food Pot app and platform and processed by One Food Pot on such customer’s behalf should be deleted as requested by such customer.
                            </p>
                            <p>
                                This policy defines how this data would be deleted, and what the customer can do to retrieve the data before its deletion.
                            </p>

                            <h5>Deletion Process</h5>

                            <p>
                                Please send an Fill form by clicking{" "}
                <a href="https://www.jotform.com/assign/241596320426152/241647486469068">
                  Account deletion Form
                </a>{" "}
                and onefoodpot will delete all the customer data in the platform
                within 10 days. </p> 

                            <h5>Disposal/Destruction Policy</h5>

                            <p>
                                Destruction is defined as physical or technical destruction, sufficient to render the information contained in the records irretrievable by ordinary commercially available means.
                            </p>

                            <p>
                                The One Food Pot Security team shall maintain and enforce a detailed list of approved destruction methods appropriate for each type of archived information, whether on physical storage or in database records or backup files.
                            </p>

                            <p>
                                Paper records shall be shredded using secure, locked consoles designated in each office, from which waste shall be periodically collected by security-screened personnel for disposal.
                            </p>




                        </div>

                    </div>

                </div> 
                <FooterLink></FooterLink>

            </div>

        </section>
    );
};

export default AccountDetailsPolicy;
