import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import usePostAxios from "../../hooks/usePostAxios";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import AppLoader from "../../component/AppLoader";
import { css } from "@emotion/css";

const AppCms = () => {
  const [content, setContent] = useState("");
  const [newContent, setNewContent] = useState("");

  const [isLoading, postData] = usePostAxios("/api/cms");

  const [isCmsLoading, isCmsError, dataCms, errorCms, reloadCms] =
    useFetchAxios("/api/cms");

  useEffect(() => {
    if (dataCms) {
      setContent(dataCms.cms);
    }
  }, [dataCms]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newContent.length) {
      cogoToast.error("Please add content to editor");
      return;
    }

    const [data, error] = await postData({ content: newContent });

    if (data) {
      console.log(data);
      cogoToast.success("Content successfully updated");
      return;
    }

    if (error) {
      console.log(error);
      cogoToast.error("Trouble updating content");
      return;
    }
  };

  if (isCmsLoading) {
    return <AppLoader />;
  }

  return (
    <div>
      <div class="card border-0" style={{ width: "100%" }}>
        <div class="card-header" style={{ backgroundColor: "#fff" }}>
          <h4
            class={
              "card-title" +
              " " +
              css`
                @media only screen and (max-width: 425px) {
                  font-size: 16px;
                }
              `
            }
          >
            Cms (How does it work!)
          </h4>
        </div>
        <div class="card-body">
          <div class="basic-form">
            <form>
              <div class="form-group">
                <Editor
		  apiKey='kima73328xb9w6pyufb8c492sw1wbsvhbao361rjqqbb2lzz'
                  initialValue={content}
                  init={{
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    selector: "textarea",
                    height: 350,
                  }}
                  onChange={(e) => setNewContent((x) => e.target.getContent())}
                />
              </div>
              <button className="btn style1" onClick={handleSubmit}>
                update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCms;
