import React from "react";
import AppLoader from "../component/AppLoader";
import { useFetchAxios } from "../hooks/useFetchAxios";
// import moment from "moment-timezone";
import dayjs from "dayjs";

const AppOrder = () => {
  const [isLoading, isError, data, error, reload] =
    useFetchAxios("/api/user/orders");

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <div>
      <section className="menu-section order-section hero-img-area">
        <img
          src="assets/images/bg-img/shape-3.png"
          alt="Image"
          className="login-shape-2"
        />
        <img
          src="assets/images/bg-img/shape-2.png"
          alt="Image"
          className="login-shape-1"
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading-4 mt-3">Your Order </h1>
            </div>
            <div className="bg-color mt-20">

              {data.length ? (data.map((x) => {
                return (
                  <div className="col-lg-12 p-3">
                    <div className="your-order-box">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="dishes-img">
                            <img
                              src={`/api/file/${x?.product?.img}`}
                              alt=""
                              className="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 or-details">
                          <div className="order-details">
                            <h4 className="res-name">{x?.product?.name}</h4>
                            <h6 className="items-price">
                              <span>{x.qty} Items</span> ${x.totalPrice}
                            </h6>
                            <h6 className="items-price">
                              <span>Payment type</span>

                              {x.isOfline ? "cash on delivery" : "online"}
                            </h6>
                            <h6 className="items-price">
                              <span>Payment Status</span>

                              {x.isPaid
                                ? "Paid"
                                : x.isOfline
                                  ? "Pending"
                                  : "Fail"}
                            </h6>
                            <h6 className="items-price">
                              <span>Delivery Location</span>

                              {x?.address?.name || "-"}
                            </h6>
                            <h6 className="date-status">
                              <span>
                                {dayjs(x.createdAt)
                                  .tz("EST")
                                  .format("D MMMM YYYY, h:mm a")}
                                {/* {moment(x.createdAt)
                                  .tz("EST")
                                  .format("Do MMMM YYYY, h:mm a")} */}
                              </span>{" "}
                              {!(x.isPaid === false && x.isOfline === false)
                                ? x.isDeliverd
                                  ? "Delivered"
                                  : "Delivery pending"
                                : "Delivery cancelled"}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })) : (<div className="text-center">
                <h1 class="display-6">No Orders Placed Yet!!!</h1>
              </div>)
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppOrder;
