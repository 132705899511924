import React from "react";
import "yup-phone-lite";
import FooterLink from "../FooterLink/FooterLink";
import AppRegistration from "./AppRegistration";
import AppLogin from "./AppLogin";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import AppLoader from "../../component/AppLoader";

const AppAuth = (props) => {
    const [isLoading, isError, data, error] = useFetchAxios(
        "/api/public/location"
    );
    return (
        <div>
            <section className="login-wrap pt-50 pb-50" id="registration_form">
                <img
                    src="assets/images/bg-img/shape-1.png"
                    alt="background-shape1"
                    className="login-shape-1"
                />
                <img
                    src="assets/images/bg-img/shape-2.png"
                    alt="background-shape1"
                    className="login-shape-2"
                />
                {
                    isLoading ? (<AppLoader />) : (
                        <>
                            <div className="container login-form py-5">
                                <div className="row">
                                    <div className="col-md-6 registration-container">
                                        <AppLogin props={props} />
                                    </div>
                                    <div className="col-md-6">
                                        <AppRegistration locationData={data} />
                                    </div>
                                </div>
                            </div>
                            <FooterLink></FooterLink>
                        </>
                    )
                }

            </section >
        </div >
    );
};

export default AppAuth;
