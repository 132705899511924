import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";

const initSchema = {
  pass: "",
  cPass: "",
};

const validationSchema = Yup.object().shape({
  pass: Yup.string().required().min(6).max(18),
  cPass: Yup.string()
    .oneOf([Yup.ref("pass"), null])
    .required(),
});

const AppNewPassword = (props) => {
  const { push } = useHistory();

  const handleSubmit = (val, { resetForm }) => {
    axios
      .post("/api/user/rw-pass", {
        pass: val.pass,
        jwtToken: props.match.params.jwt,
      })
      .then((res) => {
        cogoToast.success("Your password is updated successfully");
        resetForm();
        push("/auth");
        return;
      })
      .catch((err) => {
        cogoToast.error("Your password reset token is expired");
        return;
      });
  };

  return (
    <div>
      <section className="login-wrap pt-100 pb-70" id="Forgot_form">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2">
              <div className="login-form">
                <div className="login-header bg-green">
                  <h2 className="text-center mb-0">Create new password</h2>
                </div>
                <div className="login-body">
                  <Formik
                    initialValues={initSchema}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                    }) => {
                      return (
                        <form
                          onSubmit={handleSubmit}
                          onChange={handleChange}
                          className="form-wrap"
                          action="#"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label for="email">Password</label>
                                <Form.Control
                                  name="pass"
                                  type="password"
                                  isInvalid={!!touched.pass && !!errors.pass}
                                />
                              </div>
                              <div className="form-group">
                                <label for="email">Confirm password</label>
                                <Form.Control
                                  name="cPass"
                                  type="password"
                                  isInvalid={!!touched.cPass && !!errors.cPass}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn style1 w-40 d-block"
                                >
                                  Reset Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppNewPassword;

// import React from "react";

// const AppNewPassword = () => {
//   return <div>AppNewPassword</div>;
// };

// export default AppNewPassword;

// %PUBLIC_URL%/
