import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "react-bootstrap";

function generateDownload(canvas, crop, setImg, toggle) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      setImg(blob);
      toggle();
    },
    "image/png",
    1
  );
}

export default function App({ img, setImg, show, toggle }) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 12 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Croping Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactCrop
          src={img}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
        <div style={{ display: "none" }}>
          <canvas
            ref={previewCanvasRef}
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={toggle}>
          close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() =>
            generateDownload(
              previewCanvasRef.current,
              completedCrop,
              setImg,
              toggle
            )
          }
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
