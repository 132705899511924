import { css } from "@emotion/css";
import axios from "axios";
import cogoToast from "cogo-toast";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import AppLoader from "../../component/AppLoader";
import AppWropForm from "../../component/AppWropForm";
import { useFetchAxios } from "../../hooks/useFetchAxios";
const { Parser } = require("json2csv");

const json2csvParser = new Parser();

const RenderModal = ({ show, handleClose, x }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Payment Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered responsive className={"text-center" + " " + tableCss}>
          <tbody>
            <tr>
              <th>
                <span>Payment Method</span>
              </th>
              <th>
                <span>
                  {x?.isGpay === true
                    ? "GPay"
                    : x.paypalInfo
                      ? "Paypal"
                      : "Stripe"}
                </span>
              </th>
            </tr>
            <tr>
              <th>
                <span>Payment Status </span>
              </th>
              <th>
                <span>{x.isPaid ? "Paid" : "Cancel"}</span>
              </th>
            </tr>
            {x?.isGpay === true ? (
              <>
                <tr>
                  <th>
                    <span>Order ID</span>
                  </th>
                  <th>
                    <span>{x?.googlePay?.id || "-"}</span>
                  </th>
                </tr>
              </>
            ) : x.paypalInfo ? (
              <>
                <tr>
                  <th>
                    <span>Order ID</span>
                  </th>
                  <th>
                    <span>{x.paypalInfo.orderID}</span>
                  </th>
                </tr>
                <tr>
                  <th>
                    <span>Payer ID</span>
                  </th>
                  <th>
                    <span>{x.paypalInfo.payerID}</span>
                  </th>
                </tr>
                <tr>
                  <th>
                    <span>Facilitator Access Token</span>
                  </th>
                  <th style={{ lineBreak: "anywhere" }}>
                    <span>{x.paypalInfo.facilitatorAccessToken}</span>
                  </th>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <th style={{ lineBreak: "anywhere" }}>
                    <span>Order ID</span>
                  </th>
                  <th style={{ lineBreak: "anywhere" }}>
                    <span>{x.stripeInfo.id}</span>
                  </th>
                </tr>
                <tr>
                  <th style={{ lineBreak: "anywhere" }}>
                    <span>Payment ID</span>
                  </th>
                  <th style={{ lineBreak: "anywhere" }}>
                    <span>{x.stripeInfo.payment_intent}</span>
                  </th>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const RenderTable = ({ x, reload }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleToggleStatus = () => {
    axios
      .get(`/api/user/all-payment/toggle/${x._id}`)
      .then((res) => {
        reload();

        cogoToast.success("Payment delivery status changed successfully");
      })
      .catch((err) => {
        cogoToast.error("Trouble updating delivery status");
      });
  };

  const handleDelete = () => {
    axios
      .get(`/api/user/all-payment/status/${x._id}`)
      .then((res) => {
        reload();
        cogoToast.success("Payment status deleted successfully");
      })
      .catch((err) => {
        cogoToast.error("Trouble updating payment status");
      });
  };

  const handleToggleCod = () => {
    axios
      .get(`/api/user/all-payment/cod/toggle/${x._id}`)
      .then((res) => {
        reload();

        cogoToast.success("Cash on delivery status changed successfully");
      })
      .catch((err) => {
        cogoToast.error("Trouble updating Cash on delivery status");
      });
  };

  return (
    <>
      <RenderModal show={show} handleClose={handleClose} x={x} />
      <tr className={tableStyle}>
        <td>{x.customId}</td>
        <td>{x.user.email}</td>
        <td>{x.product.name}</td>
        <td>{x.qty}</td>
        <td>{x.tax}%</td>
        <td>{x.isTip ? x.tip : 0}</td>
        <td>{dayjs(x.createdAt).tz("EST").format("D MMMM YYYY, h:mm a")}</td>
        <td>{parseFloat(x.totalPrice).toFixed(2)}</td>
        <td>{x.isOfline ? "-" : x.isPaid ? "Paid" : "Fail"}</td>
        <td>
          {x.isOfline ? (
            <div onClick={handleToggleCod} class={"chip" + " " + chipsCss}>
              <input type="checkbox" name="fruit[]" value="b" checked="" />
              <span>{x.isPaid ? "Paid" : "Pending"}</span>
              <button type="button" class="zap">
                {x.isPaid ? <Checked /> : <Cancel />}
              </button>
            </div>
          ) : (
            "-"
          )}
        </td>
        <td>
          <div onClick={handleToggleStatus} class={"chip" + " " + chipsCss}>
            <input type="checkbox" name="fruit[]" value="b" checked="" />
            <span>{x.isDeliverd ? "Delivered" : "Pending"}</span>
            <button type="button" class="zap">
              {x.isDeliverd ? <Checked /> : <Cancel />}
            </button>
          </div>
        </td>
        <td>
          <button
            className="btn style1"
            style={{ float: "none" }}
            onClick={handleShow}
          >
            view
          </button>
        </td>
        <td>
          <button
            className="btn style1"
            style={{ float: "none" }}
            onClick={handleDelete}
          >
            <TrashIcon />
          </button>
        </td>
      </tr>
    </>
  );
};

const AppPaymentList = () => {
  const [isLoading, isError, data, error, reload] = useFetchAxios(
    "/api/user/all-payment"
  );
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const handleExportToExcel = () => {
    const myData = [];

    data.map((x) =>
      myData.push({
        orderId: x.customId,
        userEmail: x.user.email,
        "Food Name": x.product.name,
        Qty: x.qty,
        "Tax (%)": x.tax,
        Tip: x.isTip ? String(x.tip) : "0",
        Date: dayjs(x.createdAt).tz("EST").format("D MMMM YYYY, h:mm a"),
        "Total Price": x.totalPrice,
        "Payment Type": x.isOfline ? "offline" : "online",
        "Payment Status": x.isPaid ? "Paid" : "Pending/Cancel",
        Deliverd: x.isDeliverd ? "Delivered" : "Pending",
        "Payment Method": x.isOfline
          ? "Cash on delivery"
          : x.paypalInfo
          ? "Paypal"
          : "Stripe",
        "Paypal Order ID": x?.paypalInfo?.orderID || "-",
        "Stripe Order ID": x?.stripeInfo?.id || "-",
        "Stripe Payment ID": x?.stripeInfo?.payment_intent || "-",
      })
    );

    const csv = json2csvParser.parse(myData);

    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([csv], { type: "text/csv" }));

    const name = `onefood orders ${dayjs().tz("EST").format("DD-MM-YYYY")}`;

    a.download = `${name}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  
  const onSearchChange = (e) => {
    if (e.target.value && e.target.value.length) {
      setFilteredData(filteredData.filter(value => value.user.email.includes(e.target.value)))
    } else {
      setFilteredData(data)
    }
  }

  return (
    isLoading ? (<AppLoader />) : (<AppWropForm
      title={"Orders"}
      isExportToExcel={true}
      exportToExcel={handleExportToExcel}
    >
      <div className="form-group mb-2" style={{ width: '35%' }}>
        <input className="form-control" type="text" placeholder="Search User" onChange={onSearchChange} />
      </div>
      <Table striped bordered hover responsive className="text-center">
        <thead>
          <tr>
            <th>orderId</th>
            <th>User Email</th>
            <th>Food Name</th>
            <th>Qty</th>
            <th>Tax</th>
            <th>Tip</th>
            <th>Date</th>
            <th>Total Price</th>
            <th>Payment Status (online) </th>
            <th>Payment Status (ofline) </th>
            <th>Deliverd </th>
            <th>Payment Info</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredData) &&
            filteredData.map((x, i) => {
              return (
                <RenderTable
                  x={x}
                  index={i + 1}
                  key={x.customId}
                  reload={reload}
                />
              );
            })}
        </tbody>
      </Table>
    </AppWropForm>)
  );
};

export default AppPaymentList;

const Checked = () => {
  return (
    <svg
      style={{
        color: "#156a15",
        marginBottom: "0.3rem",
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-check2-circle"
      viewBox="0 0 16 16"
    >
      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
    </svg>
  );
};

const Cancel = () => {
  return (
    <svg
      style={{ color: "black" }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-exclamation-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
  );
};

const TrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fill-rule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);

const tableStyle = css`
  .chip {
    position: relative;
    display: inline-table;
    margin-right: 4px;
    font-family: sans-serif;
    font-size: 12.5px;
    line-height: 26px;
    padding: 0;
    background: #f2f2f2;
    border: thin solid #d2d2d2;
    border-radius: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    animation: fadeIn ease 0.6s;
    transition: background-color 0.8s;
  }

  .chip input {
    position: absolute;
    left: -1000px;
  }

  .chip .zap,
  .chip span {
    display: table-cell;
    padding: 0;
    vertical-align: top;
  }

  .chip span {
    padding: 0 0 0 12px;
  }

  .chip .zap {
    background: transparent;
    border: 0;
    padding: 0;
    /* opacity: 0.3; */
    font-family: "Material Icons";
    line-height: inherit;
    font-size: 16px;
    font-weight: normal;
    height: 26px;
    width: 26px;
    text-align: center;
  }

  .chip .zap:hover {
    /* opacity: 1; */
  }
`;

const tableCss = css`
  th {
    font-weight: 600 !important;
  }
`;

const chipsCss = css`
  :hover {
    cursor: pointer;
  }
`;
