import { css } from "@emotion/css";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormControl, Modal, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
// import "yup-phone";
import "yup-phone-lite";
import AppLoader from "../component/AppLoader";
import FieldError from "../component/FieldError";
import { useFetchAxios } from "../hooks/useFetchAxios";
import usePostAxios from "../hooks/usePostAxios";
import { validationErrorMessage } from "../utils/constant";
import { useDispatch } from "react-redux";
import { updateUser } from "../actions/auth";
import { formatPhoneNumber } from "../utils/utils";

const validationSchema = Yup.object().shape({
  name: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()).min(4).max(24),
  email: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()).email(validationErrorMessage.email()),
  mobile: Yup.string(validationErrorMessage.type('string')).phone("CA", validationErrorMessage.phone()).required(validationErrorMessage.required()),
  delivery: Yup.string().required(),
});

const changePassValidation = Yup.object().shape({
  oPass: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  nPass: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required())
  .min(6, 'Password length must be between 6 to 18')
  .max(18, 'Password length must be between 6 to 18'),
  cNPass: Yup.string()
    .oneOf([Yup.ref("nPass"), null],'Password do not match')
    .required(validationErrorMessage.required()),
});

const changePassInit = {
  oPass: "",
  nPass: "",
  cNPass: "",
};

const AppProfile = () => {
  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    try {
      e.preventDefault();
    } catch (err) {}

    setShow(false);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const [isLoading, isError, data, error] = useFetchAxios(
    "/api/user/auth/user"
  );

  const [isLocationLoading, isLocationError, LocationData] = useFetchAxios(
    "/api/public/location"
  );

  const [isSendingData, postData] = usePostAxios("/api/user/update");

  const [isChangePassLoading, postChangePass] = usePostAxios(
    "/api/user/change-password"
  );

  const { push } = useHistory();

  const dispatch = useDispatch();

  const [initSchema, setInitSchema] = useState({
    name: "",
    email: "",
    mobile: "",
    delivery: "",
  });

  useEffect(() => {
    if (data) {
      const { email, mobile, name, delivery } = data;
      setInitSchema({
        name: name,
        email: email,
        mobile: mobile,
        delivery: delivery._id,
      });
    }
  }, [data]);

  if (isLoading || isLocationLoading) {
    return <AppLoader />;
  }

  const handleChangePass = async (val) => {
    const [data, err] = await postChangePass(val);
    if (data) {
      handleClose();
      cogoToast.success("your password updated successfully");
      return;
    }

    if (err) {
      handleClose();
      cogoToast.error("your old password does not match");
      return;
    }
  };

  const handleSubmit = async (val) => {
    const { name, mobile, delivery } = val;

    const [data, err] = await postData({ name, mobile, delivery });

    if (data) {
      await dispatch(updateUser({name: name }));
      cogoToast.success("User information updated successfully");
    }

    if (err) {
      cogoToast.error(err);
    }
  };

  return (
    <div>
      <section className="profile-section hero-img-area">
        <img
          src="assets/images/bg-img/shape-3.png"
          alt="Image"
          className="login-shape-2"
        />
        <img
          src="assets/images/bg-img/shape-2.png"
          alt="Image"
          className="login-shape-1"
        />

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={changePassInit}
            validationSchema={changePassValidation}
            onSubmit={handleChangePass}
          >
            {({ handleChange, handleSubmit, values, touched, errors }) => {
              return (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label>Old password</Form.Label>
                      <Form.Control
                        name="oPass"
                        type="password"
                        isInvalid={!!touched.oPass && !!errors.oPass}
                      />
                      {!!touched.oPass &&  <FieldError errorMessage={errors.oPass} /> }
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>New password</Form.Label>
                      <Form.Control
                        name="nPass"
                        type="password"
                        isInvalid={!!touched.nPass && !!errors.nPass}
                      />
                      {!!touched.nPass &&  <FieldError errorMessage={errors.nPass} /> }
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm New password</Form.Label>
                      <Form.Control
                        name="cNPass"
                        type="password"
                        isInvalid={!!touched.cNPass && !!errors.cNPass}
                      />
                      {!!touched.cNPass &&  <FieldError errorMessage={errors.cNPass} /> }
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      // variant="secondary"
                      className="btn style1"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <Button variant="primary" type="submit">
                      Update password
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading-4 mt-3">Profile</h1>
            </div>
            <div className="col-lg-4 view-pro-details">
              <div className="profile text-center rounded-3">
                <div className="row">
                  <div className="col-lg-12 mb-2">
                    <img
                      src="assets/images/user/user.png"
                      alt="avatar"
                      className="rounded-circle w-50"
                    />
                  </div>
                  <div className="col-lg-12">
                    <h2>{initSchema.name}</h2>
                  </div>
                  <div className="col-lg-12">
                    <h5>{initSchema.email}</h5>
                  </div>
                  <div className="col-lg-12">
                    <h5>+1 {initSchema.mobile}</h5>
                  </div>
                  <div className="col-lg-12">
                    <h3>Delivery Location</h3>
                    <h5>
                      {data.delivery.time} - {data.delivery.name}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <Formik
                initialValues={initSchema}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
                  values,
                  setFieldValue,
                }) => {
                  return (
                    <form
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      className="view-profile"
                    >
                      <div className="my-profile rounded-3 py-4">
                        <div className="row">
                          <div className="col-lg-12 my-2">
                            <label for="name" className="form-label font-weight-bold">Name * </label>
                            <div className="input-group">
                              <FormControl
                                className="form-control"
                                id="name"
                                type="text"
                                name="name"
                                value={values.name}
                                isInvalid={!!touched.name && !!errors.name}
                                placeholder="John"
                              />
                            </div>
                            {!!touched.name && <FieldError errorMessage={errors.name} />}
                          </div>
                          <div className="col-lg-12 my-2">
                            <label for="email" className="form-label font-weight-bold">Email * </label>
                            <div className=" input-group">
                              <FormControl
                                className="form-control"
                                id="email"
                                type="text"
                                name="email"
                                value={values.email}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 my-2">
                            <label for="mobile" className="form-label font-weight-bold">Mobile No *</label>
                            <div className="input-group">
                              <FormControl
                                type="text"
                                className="form-control"
                                placeholder="Mobile Number"
                                value={formatPhoneNumber(values.mobile)}
                                onChange={(e) =>  
                                  setFieldValue("mobile", (e.target.value))}
                                
                                // name="mobile"
                                isInvalid={!!touched.mobile && !!errors.mobile}
                              />
                            </div>
                               <FieldError errorMessage={errors.mobile} />
                          </div>

                          <div className="col-12 my-2">
                            <div className="form-group">
                              <button
                                onClick={handleShow}
                                className={
                                  "btn btn-primary" +
                                  " " +
                                  css`
                                    margin-right: -1rem !important;
                                    float: none !important;
                                  `
                                }
                              >
                                Change password
                              </button>
                              <button
                                type="submit"
                                className={
                                  "btn style1" +
                                  " " +
                                  css`
                                    float: none !important;
                                    margin-left: 2rem;
                                  `
                                }
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default AppProfile;
