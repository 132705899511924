import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { FormControl } from "react-bootstrap";
import usePostAxios from "../../hooks/usePostAxios";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import jwt_decode from "jwt-decode";

import {useDispatch} from "react-redux";
import { login } from "../../actions/auth";

const initSchema = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required().min(6).max(18),
});

const AppLogin = ({ props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const [isSendingData, postData] = usePostAxios(
    "/api/user/auth/user?login=true"
  );
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleSubmit = async (val) => {
    const [data, err] = await postData(val);

    if (data) {
      await dispatch(login({
        email: data.user.email,
        name: data.user.name,
        token: data.token,
      }));
      console.log(data);
      try {
        const parsed = queryString.parse(props.location.search);

        if (parsed?.redirect) {
          const { redirect } = parsed;
          push(redirect);
          return;
        }

        const { isAdmin } = jwt_decode(data.token);
        if (isAdmin) {
          push("/admin/dashboard");

          return;
        }

        if (!isAdmin) {
          push("/?login=true");
          return;
        }
      } catch (err) {
        push("/?login=true");
        return;
      }
    }

    if (err) {
      cogoToast.error(err);
    }
  };

  return (
    <div className="login-body">
      <div className="login-header bg-green">
        <h2 className="text-center mb-20">Login</h2>
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initSchema}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          handleChange,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              onChange={handleChange}
              className="form-wrap"
            >
              <div className="row">
                <div className="my-2">
                  <label for="email-login" className="form-label font-weight-bold">Email * </label>
                  <div className="col-lg-12 input-group">
                    <FormControl
                    className="form-control"
                      id="email-login"
                      type="text"
                      name="email"
                      placeholder="name@email.com"
                    />
                  </div>
                </div>
                <div className="my-2">
                  <label for="pwd-login" className="form-label font-weight-bold">Password * </label>
                  <div className="col-lg-12 input-group">
                    <FormControl
                      className="form-control"
                      id="pwd-login"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                    />
                    <span class="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (<i class="fas fa-eye" id="show_eye"></i>) : (<i class="fas fa-eye-slash" id="hide_eye"></i>)}
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="form_group mb-20">
                    <input type="checkbox" id="test_1" />
                    <label for="test_1">Remember Me</label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 forget-pass text-md-end mb-20">
                  <Link
                    to="/forgot-password"
                    id="forgot-button"
                    className="link style2 link-dark"
                    style={{
                      textDecoration: "underline !important",
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <button
                      disabled={isSendingData}
                      type="submit"
                      href="order-confirmation.html"
                      className="btn style1 w-100 d-block"
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </div>
            </form >
          );
        }}
      </Formik >
    </div >
  )
};

export default AppLogin;
