import React, { useEffect, useState } from "react";
import AppLoader from "../../component/AppLoader";
import AppWropForm from "../../component/AppWropForm";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import { Table, Modal, Button, Form } from "react-bootstrap";
import cogoToast from "cogo-toast";

import { Formik } from "formik";
import * as Yup from "yup";
// import "yup-phone";
import "yup-phone-lite";

import usePostAxios from "../../hooks/usePostAxios";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(4).max(24),
  email: Yup.string().required().email(),
  mobile: Yup.string().phone("CA").required(),
  delivery: Yup.string().required(),
});

const HandleModal = ({ show, handleClose, x, locationData, reload }) => {
  const [initSchema, setInitSchema] = useState({
    name: x.name,
    email: x.email,
    mobile: x.mobile,
    delivery: x.delivery,
  });

  const [isLoading, postData] = usePostAxios("/api/user/update");

  const handleSubmit = async (val) => {
    const [data, error] = await postData({ ...val, userId: x._id });

    if (data) {
      cogoToast.success("User information updated successfully");
      reload();
      return;
    }

    if (error) {
      cogoToast.error("Trouble updating user information");
      return;
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Customer</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initSchema}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Modal.Body>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      value={values.name}
                      isInvalid={!!touched.name && !!errors.name}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled
                      // name="email"
                      value={values.email}
                      // isInvalid={!!touched.email && !!errors.email}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      value={values.mobile}
                      onChange={(e) => setFieldValue("mobile", e.target.value)}
                      isInvalid={!!touched.mobile && !!errors.mobile}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="select"
                      name="delivery"
                      value={values.delivery}
                      isInvalid={!!touched.delivery && !!errors.delivery}
                    >
                      <option value={""}>--- Select Area ---</option>
                      {locationData.map((x) => {
                        return (
                          <option value={x._id}>
                            {x.name} - {x.time}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const RenderTable = ({ x, index, key, locationData, reload }) => {
  const [dLocation, setDLocation] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    try {
      const d = locationData.filter((Y) => Y._id === x.delivery);
      console.log("LOCATION", x.delivery);
      const { name, time } = d[0];

      setDLocation(`${name} - ${time}`);
    } catch (err) {
      setDLocation(`LOCATION DELETED`);
    }
  }, []);

  return (
    <>
      <tr>
        <HandleModal
          show={show}
          handleClose={handleClose}
          x={x}
          locationData={locationData}
          reload={reload}
        />
        <td>{index}</td>
        <td>{x.name}</td>
        <td>{x.email}</td>
        <td>{x.mobile}</td>
        <td>{dLocation}</td>
        <td>
          <button
            className="btn style1"
            style={{ float: "unset" }}
            onClick={handleShow}
          >
            <Pencil />
          </button>
        </td>
      </tr>
    </>
  );
};

const AppCustomer = () => {
  const [isLoading, isError, data, error, reload] = useFetchAxios(
    "/api/user/admin/info"
  );

  const [isLocationLoading, isLocationError, locationData] = useFetchAxios(
    "/api/public/location"
  );

  console.log("LOADING STATUS OUTSIDE", isLoading);

  if (isLoading || isLocationLoading) {
    return <AppLoader />;
  }

  return (
    <AppWropForm title={"Customer"}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((x, i) => (
              <RenderTable
                x={x}
                index={i + 1}
                key={x._id}
                locationData={locationData}
                reload={reload}
              />
            ))}
        </tbody>
      </Table>
    </AppWropForm>
  );
};

export default AppCustomer;

const Pencil = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-pen"
    viewBox="0 0 16 16"
  >
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
  </svg>
);
