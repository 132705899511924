import { css, Global } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AppWropForm from "../../component/AppWropForm";
import { Formik } from "formik";
import * as Yup from "yup";
import AppLoader from "../../component/AppLoader";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import Select from "react-select";
import axios from "axios";
import cogoToast from "cogo-toast";
import AppImgCrop from "../../component/AppImgCrop";
import { validationErrorMessage } from "../../utils/constant";
import FieldError from "../../component/FieldError";

const validationSchema = Yup.object().shape({
  name: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  description: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  offerdBy: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  yelpLink: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  deliveryLocation: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
        value: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
      })
    )
    .required(validationErrorMessage.required()),
  img: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  regularPrice: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  discountPrice: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  tip: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  tax: Yup.string(validationErrorMessage.type('string')).required(validationErrorMessage.required()),
  totalQuantity: Yup.number(validationErrorMessage.type('numeric')).positive( validationErrorMessage.minValue(0)).required(validationErrorMessage.required()),
  remainingQuantity: Yup.number(validationErrorMessage.type('numeric')).min(0,"Please enter a positive number").required(validationErrorMessage.required())
});

const AppRecipe = () => {
  const [isLoading, isError, data, err, reload] = useFetchAxios("/api/recipe");
  const [imgData, setImgData] = useState(null);
  const [imgStrData, setImgStrData] = useState("");

  const [locationCounts, setLocationCounts] = useState([]);

  const [isLocationLoading, isLocationError, locationData] = useFetchAxios(
    "/api/public/location"
  );

  const imgRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cropImg, setCropImg] = useState(null);

  const toggleCropModal = () => {
    setIsModalOpen((x) => !x);
  };

  const [initSchema, setInitSchema] = useState({
    name: "",
    description: "",
    offerdBy: "",
    yelpLink: "",
    deliveryLocation: "",
    img: "",
    regularPrice: "",
    discountPrice: "",
    tip: "",
    tax: "",
    totalQuantity: 0,
    remainingQuantity: 0
  });

  const handleSubmit = async (val) => {
    const {
      deliveryLocation,
      description,
      discountPrice,
      img,
      name,
      offerdBy,
      regularPrice,
      tax,
      tip,
      yelpLink,
      totalQuantity,
      remainingQuantity
    } = val;

    let newImg;

    if (imgData !== null) {
      const fd = new FormData();

      fd.append("image", imgData);

      await axios
        .post("/api/file/upload", fd)
        .then((res) => {
          newImg = res.data.data;
        })
        .catch((err) => {
          console.log("XYZ", err);

          cogoToast.error("Trouble uploading images");
        });
    }

    try {
      const state = JSON.parse(window.localStorage.getItem("STATE"));
      axios.defaults.headers.common["Authorization"] = state?.token || "";
    } catch (err) {
      console.log(err);
    }

    await axios
      .post("/api/recipe", {
        deliveryLocation,
        description,
        discountPrice,
        img: newImg || img,
        name,
        offerdBy,
        regularPrice,
        tax,
        tip,
        yelpLink,
        totalQuantity,
        remainingQuantity
      })
      .then((res) => {
        setImgData(null);
        setImgStrData(newImg || img);
        reload();
        cogoToast.success("Successfully updated recipe of the day");
      })
      .catch((err) => {
        cogoToast.error("Something went wrong on server");
      });
  };

  useEffect(() => {
    if (data) {
      setImgStrData(data.img);

      let d = [];

      if (locationData) {
        try {
          const lm = locationData.filter((item) =>
            data.deliveryLocation.includes(item._id)
          );

          lm.map((x) =>
            d.push({
              value: x._id,
              label: `${x.name} - ${x.time}`,
            })
          );
        } catch (err) {
          console.log(err);
        }
      }

      try {
        setInitSchema((x) => ({
          ...x,
          name: data?.name || "",
          tip: data?.tip || "",
          tax: data?.tax || "",
          description: data?.description || "",
          offerdBy: data?.offerdBy || "",
          yelpLink: data?.yelpLink || "",
          deliveryLocation: d || "",
          img: data?.img || "",
          regularPrice: data?.regularPrice || "",
          discountPrice: data?.discountPrice || "",
          totalQuantity: data?.totalQuantity,
          remainingQuantity: data?.remainingQuantity,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  }, [data, locationData]);

  if (isLoading || isLocationLoading) {
    return <AppLoader />;
  }

  return (
    <AppWropForm title={"Recipe of the day"}>
      <Global styles={bodyStyle} />

      <AppImgCrop
        img={cropImg}
        setImg={setImgData}
        show={isModalOpen}
        toggle={toggleCropModal}
      />

      <Formik
        initialValues={initSchema}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          console.log(values.deliveryLocation);

          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col xs="12" className="col-12 mx-md-3">
                  <Row>
                    <Col
                      md="4"
                      className="border"
                      onClick={() => imgRef.current.click()}
                    >
                      <img
                        className="img-fluid"
                        src={
                          imgData
                            ? URL.createObjectURL(imgData)
                            : `/api/file/${values.img}`
                        }
                      />
                    </Col>
                    <Col md="7">
                      <Form.Group>
                        <Form.Label>Recipe Name * </Form.Label>
                        <Form.Control
                          name="name"
                          value={values.name}
                          isInvalid={!!errors.name}
                        />
                        <FieldError errorMessage={errors.name} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Recipe Tip ($) * </Form.Label>
                        <Form.Control
                          name="tip"
                          value={values.tip}
                          isInvalid={!!errors.tip}
                        />
                        <FieldError errorMessage={errors.tip} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Recipe Total Quantity * </Form.Label>
                        <Form.Control
                          name="totalQuantity"
                          value={values.totalQuantity}
                          isInvalid={!!errors.totalQuantity}
                        />
                        <FieldError errorMessage={errors.totalQuantity} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Remaining Quantity * </Form.Label>
                        <Form.Control
                          name="remainingQuantity"
                          value={values.remainingQuantity}
                          isInvalid={!!errors.remainingQuantity}
                        />
                        <FieldError errorMessage={errors.remainingQuantity} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Delivery location  * </Form.Label>
                        <Select
                          isMulti
                          defaultValue={values.deliveryLocation}
                          options={locationData.map((x) => ({
                            value: x._id,
                            label: `${x.name} - ${x.time}`,
                          }))}
                          onChange={(e) => setFieldValue("deliveryLocation", e)}
                        />
                        <FieldError errorMessage={errors.deliveryLocation} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="6">
                  {/* <Form.Group>
                    <Form.Label>Recipe Name * </Form.Label>
                    <Form.Control
                      name="name"
                      value={values.name}
                      isInvalid={!!touched.name && !!errors.name}
                    />
                  </Form.Group> */}
                </Col>
                <Col xs="12" md="6">
                  {/* <Form.Group>
                    <Form.Label>Recipe Tip * </Form.Label>
                    <Form.Control
                      name="tip"
                      value={values.tip}
                      isInvalid={!!touched.tip && !!errors.tip}
                    />
                  </Form.Group> */}
                </Col>
                <Col xs="12" md="6">
                  {/* <Form.Group>
                    <Form.Label>Discount Price  * </Form.Label>
                    <Select
                      isMulti
                      defaultValue={values.deliveryLocation}
                      options={locationData.map((x) => ({
                        value: x._id,
                        label: `${x.name} - ${x.time}`,
                      }))}
                      onChange={(e) => setFieldValue("deliveryLocation", e)}
                    />
                  </Form.Group> */}
                </Col>
                <Col xs="12" md="6">
                  {/* <Form.Group>
                    <Form.Label>Recipe Tax * </Form.Label>
                    <Form.Control
                      name="tax"
                      value={values.tax}
                      isInvalid={!!touched.tax && !!errors.tax}
                    />
                  </Form.Group> */}
                </Col>
                <Col xs="12">
                  <Form.Group>
                    <Form.Label>Recipe Discription * </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows="4"
                      value={values.description}
                      isInvalid={!!errors.description}
                    />
                    <FieldError errorMessage={errors.description} />
                  </Form.Group>
                </Col>
                <Col xs="12" md="6">
                  <Form.Group>
                    <Form.Label>Yelp Link * </Form.Label>
                    <Form.Control
                      name="yelpLink"
                      value={values.yelpLink}
                      isInvalid={!!errors.yelpLink}
                    />
                    <FieldError errorMessage={errors.yelpLink} />
                  </Form.Group>
                </Col>
                <Col xs="12" md="6">
                  <Form.Group>
                    <Form.Label>Offerd By * </Form.Label>
                    <Form.Control
                      name="offerdBy"
                      value={values.offerdBy}
                      isInvalid={!!errors.offerdBy}
                    />
                    <FieldError errorMessage={errors.offerdBy} />
                  </Form.Group>
                </Col>
                <Col xs="12" md="4" className="d-none">
                  <Form.Group>
                    <Form.Label>
                      img
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          fontSize: "0.8rem",
                          textDecoration: "underline",
                          color: "blue",
                          marginTop: "0.3rem",
                        }}
                      >
                        <a href={`/api/file/${values.img}`} target={"_blank"}>
                          old image
                        </a>
                      </span>
                     * </Form.Label>
                    <Form.Control
                      ref={imgRef}
                      type="file"
                      onChange={(e) => {
                        if (
                          e.target.files[0].type === "image/jpeg" ||
                          e.target.files[0].type === "image/png"
                        ) {
                          const reader = new FileReader();

                          reader.addEventListener("load", () =>
                            setCropImg(reader.result)
                          );

                          reader.readAsDataURL(e.target.files[0]);

                          // setImgData(e.target.files[0]);
                          // setCropImg(e.target.files[0]);
                          toggleCropModal();
                        }
                      }}
                      isInvalid={!!touched.img && !!errors.img}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" md="4">
                  <Form.Group>
                    <Form.Label>Regular Price ($) * </Form.Label>
                    <Form.Control

                      name="regularPrice"
                      value={values.regularPrice}
                      isInvalid={!!errors.regularPrice}
                    />
                    <FieldError errorMessage={errors.regularPrice} />
                  </Form.Group>
                </Col>
                <Col xs="12" md="4">
                  <Form.Group>
                    <Form.Label>Discounted Price ($) *</Form.Label>
                    <Form.Control
                      name="discountPrice"
                      value={values.discountPrice}
                      isInvalid={!!errors.discountPrice}
                    />
                    <FieldError errorMessage={errors.discountPrice} />
                  </Form.Group>
                </Col>
                <Col xs="12" md="4">
                  <Form.Group>
                    <Form.Label>Recipe Tax (%) * </Form.Label>
                    <Form.Control
                      name="tax"
                      value={values.tax}
                      isInvalid={!!errors.tax}
                    />
                    <FieldError errorMessage={errors.tax} />
                  </Form.Group>
                </Col>
                <Col xs="12">
                  <button className="btn style1 mr-2 mt-3" type="submit" disabled={Object.keys(errors).length !== 0}>
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </AppWropForm>
  );
};

export default AppRecipe;

const bodyStyle = css`
  .row div {
    margin-top: 0.3rem;
  }

  .row div:first-of-type {
    margin-top: 0;
  }
`;
