import React, { useState } from "react";
import AppWropForm from "../../component/AppWropForm";
import * as Yup from "yup";
import { Formik } from "formik";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import AppLoader from "../../component/AppLoader";
import usePostAxios from "../../hooks/usePostAxios";
import cogoToast from "cogo-toast";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const initSchema = {
  name: "",
  time: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  time: Yup.string().required(),
});

const RenderModal = ({ show, handleClose, data, locationReload }) => {
  const [initSchema, setInitSchema] = useState({
    name: data.name,
    time: data.time,
  });

  const [isLoading, postData] = usePostAxios("/api/location");

  const handleSubmit = async (val) => {
    console.log(val);

    const dataPost = {
      update: true,
      _id: data._id,
      name: val.name,
      time: val.time,
    };

    const [dataRes, errorRes] = await postData(dataPost);

    if (dataRes) {
      locationReload();
      cogoToast.success("Location updated successfully");
      return;
    }

    if (errorRes) {
      cogoToast.error("Trouble updating location information");
      return;
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initSchema}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, handleChange, errors, values, touched }) => {
            return (
              <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Modal.Body>
                  <Row>
                    <Col xs="12" className="mt-2">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="name"
                          value={values.name}
                          isInvalid={!!touched.name && !!errors.name}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" className="mt-2">
                      <Form.Group>
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                          name="time"
                          value={values.time}
                          isInvalid={!!touched.time && !!errors.time}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const RenderTable = ({ index, data, locationReload }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = async () => {
    try {
      const state = JSON.parse(window.localStorage.getItem("STATE"));
      axios.defaults.headers.common["Authorization"] = state?.token || "";
    } catch (err) {
      console.log(err);
    }

    await axios
      .delete(`/api/location?locatinId=${data._id}`)
      .then((res) => {
        locationReload();
        cogoToast.success("Location information updated successfully");
      })
      .catch((err) => {
        cogoToast.error("Trouble updating location information");
      });
  };

  return (
    <>
      <RenderModal
        show={show}
        handleClose={handleClose}
        data={data}
        locationReload={locationReload}
      />

      <tr>
        <td>{index}</td>
        <td>{data.name}</td>
        <td>{data.time}</td>
        <td style={{ display: "flex", gap: "1rem" }}>
          <button className="btn btn-primary" onClick={handleShow}>
            <Pencil />
          </button>
          <button className="btn btn-danger" onClick={handleDelete}>
            <Trash />
          </button>
        </td>
      </tr>
    </>
  );
};

const AppLocation = () => {
  const [
    isLocationLoading,
    isLocationError,
    LocationData,
    LocationError,
    locationReload,
  ] = useFetchAxios("/api/public/location");

  const [isLoading, postData] = usePostAxios("/api/location");

  const handleSubmit = async (val, { resetForm }) => {
    const [data, error] = await postData(val);

    if (data) {
      resetForm();
      locationReload();
      cogoToast.success("Location information added successfully");
    }
    if (error) {
      cogoToast.error("Trouble updating location information");
    }
  };

  if (isLocationLoading) {
    return <AppLoader />;
  }

  return (
    <div>
      <AppWropForm title={"Locations"}>
        <div>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initSchema}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, handleChange, errors, values, touched }) => {
              return (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                  <Row>
                    <Col xs="12" md="6" className="mt-2">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="name"
                          value={values.name}
                          isInvalid={!!touched.name && !!errors.name}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6" className="mt-2">
                      <Form.Group>
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                          name="time"
                          value={values.time}
                          isInvalid={!!touched.time && !!errors.time}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mt-3">
                      <Form.Group>
                        <button type="submit" className="btn style1">
                          update
                        </button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Table striped responsive bordered hover style={{ marginTop: "2rem" }}>
          <thead>
            <tr>
              <th>No</th>
              <th> Name</th>
              <th> Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {LocationData.map((x, i) => (
              <RenderTable
                index={i + 1}
                data={x}
                locationReload={locationReload}
              />
            ))}
          </tbody>
        </Table>
      </AppWropForm>
    </div>
  );
};

export default AppLocation;

const Pencil = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-pen"
    viewBox="0 0 16 16"
  >
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
  </svg>
);

const Trash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fill-rule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);
