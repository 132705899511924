import parse from "html-react-parser";
import AppLoader from "../../component/AppLoader";
import { useFetchAxios } from "../../hooks/useFetchAxios";
import FooterLink from "../FooterLink/FooterLink";
import './PrivacyPolicy.css';
const PrivacyPolicy = () => {


    return (
        <section className="py-4">

            <div className="container">

                <div className="row">

                    <div className="col-md-12">

                        <div className="description-policy">

                            <h3 className="text-center">Privacy Policy</h3>


                            <p>
                                The categories of Personal Information we collect
                            </p>

                            <p>
                                When you visit any of our platforms including (e.g. onefoodpot.com), we may collect information from you. Some of it may be personal information. This information can include search history, IP address, screen resolution, browser used, operating system and settings, access times and referring URL. If you are using a mobile device, we may also collect data that identifies your device, your settings and your location.
                            </p>

                            <p>
                                If you order food via the Platforms, we may need additional information from you. This information can include your name (and traveler’s name if different), email address, telephone number, credit card information, billing address, order details. You can also choose to store personal information in your user account for future orders.
                            </p>

                            <p>
                                If you choose to register, we will collect your email address, password and home address, phone number and other preference information you provide us. If you choose a third-party service, like Facebook Login, to register or login, you are permitting us to use and store that information related to the account.
                            </p>

                            <p>
                                If you use our “Sign Up” service, we will collect your information to create and maintain your food preferences. This can include the personal information that may be included in the confirmation emails you forward to us.
                            </p>

                            <p>
                                If you give us personal information, you are telling us that the information is true, accurate, complete and current. You are also telling us that you have the authorization to provide it to us.
                            </p>

                            <h5>How we collect your Personal Information</h5>

                            <p>
                                We collect your personal information directly from you. For example, you provide us your personal information when you register or select a food delivery service.
                            </p>

                            <p>
                                We also collect your personal information passively. For example, we collect information about you over time and across different Websites when you visit this Platform. We also use tracking tools like cookies and beacons. To learn more about these tools and how to control them, click here.
                            </p>

                            <p>
                                We collect your personal information from third parties. This can include when you log in using a third party platform, such as Facebook.
                            </p>

                            <h5>The categories of Personal Information we collect</h5>
                            <p>
                                We use your personal information to provide you exemplary services and improve our Platforms, to better understand the users of our Platforms and services, to protect our property and to prevent damage to them.
                            </p>

                            <h5>
                                To process your selections.
                            </h5>

                            <p>
                                To display more relevant advertising and recommendations, or suppress advertising and content that you might find irrelevant.
                            </p>

                            <p>
                                To contact you, including as follows:
                            </p>

                            <ul className="privadypolicy">
                                <li> » To send food delivery confirmations; </li>
                                <li> » To send alerts and notifications you have subscribed to, including to your desktop and mobile device; </li>
                                <li> » To solicit reviews;</li>
                                <li> » When you send sign up for our food delivery newsletter; </li>
                                <li> » To get in touch if you contact ONEFOODPOT customer service; </li>
                                <li> » To send you information servicing and administrative emails; </li>
                                <li> » To send you marketing emails from a ONEFOODPOT address regarding our services or those of our partners, if you have opted-in to receive emails from us or have transacted with us, and as permitted by law. </li>
                            </ul>

                            <h5>
                                Your Choices
                            </h5>

                            <p>
                                You can manage your communication preferences in your account. Every marketing email has an opt-out URL and you can unsubscribe from such communication at any time. You cannot unsubscribe from servicing emails and administrative messages that are considered part of your account membership, unless you cancel your membership.
                            </p>

                            <h5>
                                How we share your Personal Information
                            </h5>

                            <p>
                                We may share your personal information with our corporate affiliates and with third parties for our legitimate business purposes as permitted by law. For example, we may share your personal information with suppliers who perform services on our behalf and have agreed in writing to protect and not further disclose your information.
                            </p>

                            <p>
                                If you order through the Platforms, we may share your personal information with the payment service providers and the food vendors you have ordered with. This can include online food delivery companies, restaurants. These third parties will process your personal information as data controllers in accordance with their own privacy policies.
                            </p>

                            <p>
                                We may also share your information as otherwise described to you at the time of collection. For example, if you provide information to enter a sweepstakes, we may share your information with the promotional partners.
                            </p>

                            <p>
                                We may also share your information as otherwise described to you at the time of collection. For example, if you provide information to enter a sweepstakes, we may share your information with the promotional partners.
                            </p>

                            <p>
                                If ONEFOODPOT itself (or part of its business) is sold or otherwise changes control, the owners would have access to your personal information for the uses set out herein.
                            </p>

                            <p>
                                If you provide us a review of your experience, you authorize us to publish it on all our Platforms under the screen name you provided. You also authorize us to aggregate it with other reviews.
                            </p>

                            <p>
                                We may disclose personal information if required by law, for example to law enforcement or other authorities. This includes court orders, subpoenas and orders arising from legal processes, and administrative or criminal investigations. We may also disclose your personal information if the disclosure is necessary for the prevention, detection or prosecution of criminal acts or to prevent other damage, or in response to a legal action or to enforce our rights and claims.
                            </p>

                            <p>
                                We may also share anonymous aggregated usage information with others.
                            </p>

                            <h5>
                                How we store and protect your Personal Information
                            </h5>

                            <p>
                                Our servers and data centers are located in the U.S, and our service providers may be located in the U.S. and in other countries. By providing us personal information, you agree that your personal information may be transferred to and stored in these countries. These countries may have different and/or less stringent privacy/data protection and data security rules than those of your own country. As a result, your personal information may be subject to access requests from governments, courts, or law enforcement agencies in those countries according to the laws in those countries. Subject to the applicable laws in such other countries, we will provide the necessary safeguards to maintain protections of your personal information in accordance with applicable law.
                            </p>

                            <p>
                                ONEFOODPOT has a security program intended to keep the personal information stored in our systems protected from unauthorized access and misuse. Our systems are configured with data encryption, or scrambling technologies and firewalls constructed to industry standards. We also use Secure Socket Layer (SSL) technology that protects the data you send over the Internet. Personal information may only be accessed by persons within our organizations, or our third party suppliers, who require such access to carry out the uses indicated in this Privacy Policy.
                            </p>

                            <h5>Cookies and tracking tools</h5>

                            <p>
                                ONEFOODPOT uses cookies and tracking tools.
                            </p>

                            <h5>
                                Other Websites
                            </h5>

                            <p>
                                If you click through to third party websites or other platforms, ONEFOODPOT’s privacy policy does not apply to your interactions on those sites.
                            </p>

                            <h5>
                                Children
                            </h5>

                            <p>
                                ONEFOODPOT does not knowingly collect personal information from minors. If we become aware that a minor is attempting to submit personal information, we will remove this information from our records. If you are the parent/legal guardian of a minor who has given us personal information, please contact us so we can delete it.
                            </p>

                            <h5>
                                Changes to the Privacy Policy
                            </h5>

                            <p>
                                We may modify this Privacy Policy at any time and without prior notice. We will notify you of material changes to how we use your personal information prior to implementing them. We may notify you about such a material change by email or prominent notice on our website. By using our services and Platforms after we post these changes, you indicate you accept them.
                            </p>

                            <h5>
                                Accessing and rectifying your Personal Information
                            </h5>

                            <p>
                                You are entitled at any time to obtain information about all your personal information that we store, in accordance with applicable law and without any fee. However, in exceptional cases we ask you to pay a reasonable fee before we provide the information if permitted by applicable law. We may request proof of your identity before providing this information. We may not be able to allow you to access certain personal information in some cases. For example if your personal information is connected with personal information of other persons, or for legal reasons. In such cases we will provide you with an explanation of why you cannot obtain this information. You can also withdraw your consent to the use of your personal information at any time by contacting us at the address below. You can demand deletion or rectification of your personal information, however, statutory provisions, especially those affecting ONEFOODPOT’s accounting, processing of claims, and mandatory data retention, may prohibit deletion or anonymization.
                            </p>

                            <p>
                                To exercise your rights, or if you have questions about our privacy practices or need assistance with exercising or understanding your privacy choices, please contact us at support@1foodpot.com or you may write to:
                            </p>

                            <h5>ONEFOODPOT</h5>

                            <p>
                                Attention: Harshil Bhatt <br />
                                150 Prigmore street, <br />
                                East Brunswick NJ,08816.
                            </p>

                            <h5>ONEFOODPOT uses tracking tools such as cookies</h5>

                            <p>
                                We (or our suppliers) use several common tracking tools. We use these tools to collect usage and demographic information about you over time and across different Web sites when you use our Platforms. We also have third parties (e.g. advertisers) that collect personal information this way. Tools we use may include browser cookies. Your browser will store these cookies on your computer in a small file.
                            </p>

                            <p>
                                We and our advertising partners may also use web beacons (single pixel GIF images). These web beacons are placed in the code of a Web page or an email newsletter.
                            </p>

                            <p>
                                By using the Platforms, you give us your free, unambiguous and informed consent to the use of any of the cookies and tracking tools mentioned in this policy.
                            </p>

                            <h5>ONEFOODPOT uses tracking tools for following purposes</h5>

                            <ul className="privadypolicy">
                                <li> (i) To track new visitors to the Platforms. </li>
                                <li> (ii) To help us recognize your browser as a previous visitor. This includes saving and remembering any preferences that may have been set while your browser was visiting the Platforms, such as language. </li>
                                <li> (iii) To store your username and password if you are registered with us. </li>
                                <li> (iv) To track when you book on partner websites. </li>
                                <li> (v) To help improve our website offering and for capacity planning purposes. We or our third party service providers may set analytics cookies. These allow us to gather aggregated or segmented information about the types of visitors that access our Platforms and the pages and advertisements that they view. We do not use this information to personally identify you. </li>
                            </ul>

                            <p>
                                How you can control tracking tools and opt-out of online behavioral advertising
                            </p>

                            <p>
                                Your browser may give you the ability to control cookies. How to do this varies from browser to browser. You should view the Help menu on the browser you use for further information.
                            </p>

                            <p>
                                To opt out of having your online behavior tracked for advertising purposes click here.
                            </p>

                            <p>
                                We may also use Google Analytics to collect demographic and interest data about you (such as age, gender, and interests), including through Google Analytics Demographics and Interest Reporting. You can find out more about Google Analytics and how to opt-out by going here. Choices you make are browser and device specific. Some aspects of our site use cookies to function. You may not be able to use these features if you set your device to block cookies. If you block or delete cookies or opt out of online behavioral advertising, not all of the tracking that we have described in this policy will stop.
                            </p>

                            <h5>
                                How ONEFOODPOT responds to “Do Not Track” signals
                            </h5>

                            <p>
                                Some browsers have a “Do Not Track” feature that lets you tell websites that you do not want to have your online activities tracked. These features are not yet uniform, so we are not currently set up to respond to those signals
                            </p>

                        </div>

                    </div>

                </div>
               
               <FooterLink></FooterLink>
            
            </div>
            
           
        </section>

    );
};

export default PrivacyPolicy;
